import Layout from "components/Layout";
import CustomerReviews from "components/homepageSections/CustomerReviews";
import ExperienceEnte from "components/homepageSections/ExperienceEnte";
import FAQs from "components/homepageSections/FAQs";
import Features from "components/homepageSections/Features";
import HeroSection from "components/homepageSections/HeroSection";
import MemoriesPreserved from "components/homepageSections/MemoriesPreserved";
import MentionsTicker from "components/homepageSections/MentionsTicker";
import Pricing from "components/homepageSections/Pricing";
import WhyEnte from "components/homepageSections/WhyEnte";
import { PageProps, graphql } from "gatsby";
import React from "react";
import "styles/landing.css";
import { FluidImageData } from "types";

interface IPageData {
    featureImage: FluidImageData;
    feature1: FluidImageData;
    feature2: FluidImageData;
    feature3: FluidImageData;
    feature4: FluidImageData;
}

interface HeroSectionProps {
    headingText: string;
    headingHighlightText: string;
    paragraphText: string;
    buttonText: string;
    backdropBackground: string;
    duckyShieldColor: string;
}

interface ExperienceEnteProps {
    background: string;
    heading: string;
    subheading: string;
    icon: string;
}

const Index: React.FC<PageProps<IPageData>> = ({
    path,
    data: { featureImage, feature1, feature2, feature3, feature4 },
}) => {
    const heroSectionProps: HeroSectionProps = {
        headingText: "for your photos",
        headingHighlightText: "Safe Home",
        paragraphText:
            "Store, share, and discover your memories with absolute privacy",
        buttonText: "Download",
        backdropBackground: `radial-gradient(
            70.11% 70.11% at 50% 50%,
            #86ff6d 0%,
            #67d451 32.99%,
            #58bf43 60.59%
        ),
        #58bf43`,
        duckyShieldColor: "#3EB54A",
    };

    const experienceEnteProps: ExperienceEnteProps = {
        background: "#58bf43",
        heading: "Experience Ente Photos",
        subheading: "Scan the QR code to download the app",
        icon: "ente",
    };

    return (
        <Layout {...{ path, shouldShowDownloads: true }}>
            <HeroSection images={[featureImage]} {...heroSectionProps} />
            <Features images={[feature1, feature2, feature3, feature4]} />
            <WhyEnte />
            <ExperienceEnte {...experienceEnteProps} />
            <CustomerReviews />
            <Pricing />
            <MemoriesPreserved />
            <FAQs />
        </Layout>
    );
};

export default Index;

export const pageQuery = graphql`
    query HeaderImageQuery {
        featureImage: imageSharp(
            fluid: { originalName: { eq: "feature.png" } }
        ) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        feature1: imageSharp(fluid: { originalName: { eq: "feature-1.png" } }) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        feature2: imageSharp(fluid: { originalName: { eq: "feature-2.png" } }) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        feature3: imageSharp(fluid: { originalName: { eq: "feature-3.png" } }) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        feature4: imageSharp(fluid: { originalName: { eq: "feature-4.png" } }) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
    }
`;
