import YellowCrown from "images/homepage/YellowCrown";
import React from "react";
import styled from "styled-components";
import PricingPlans from "./PricingPlans";

const Container = styled.section.attrs({
    className: "container text-center",
})`
    margin-top: 140px;
    margin-bottom: 180px;
`;

const Heading = styled.h1`
    font-weight: 800;

    @media (min-width: 992px) {
        font-size: 56px;
    }
`;

const FreeTrial = styled.span`
    color: #1db954;
`;

const SubHeading = styled.div`
    max-width: 700px;
    margin: 0 auto;
    margin-bottom: 42px;
    font-size: 17px;

    @media (min-width: 992px) {
        font-size: 20px;
    }
`;

export default function Pricing() {
    return (
        <Container id="pricing">
            <YellowCrown />
            <Heading>
                Pricing <FreeTrial>Plans</FreeTrial>
            </Heading>
            <SubHeading>Every plan can be shared with your family.</SubHeading>
            <PricingPlans />
        </Container>
    );
}
